// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["mxhQUaVTW"];

const serializationHash = "framer-hSj1m"

const variantClassNames = {mxhQUaVTW: "framer-v-53ra4q"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, text, width, ...props}) => { return {...props, xh1Qt9538: text ?? props.xh1Qt9538 ?? "S E R V I C E S"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, xh1Qt9538, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "mxhQUaVTW", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-hSj1m", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-53ra4q", className)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"mxhQUaVTW"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-0b8a334f-8203-4a0c-93ad-cecd0ccc4492, rgb(255, 255, 255))", ...style}}><Image background={{alt: "", fit: "fill", intrinsicHeight: 24, intrinsicWidth: 24, pixelHeight: 24, pixelWidth: 24, src: "https://framerusercontent.com/images/3b8QXeZBa3UU0wTjhDyYPc2U.svg"}} className={"framer-vf9g6o"} layoutDependency={layoutDependency} layoutId={"y6wNbQ6AQ"}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-line-height": "26px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-5a6a7d77-3d7f-4cac-a6d6-7ea13b9dcea9, rgb(0, 0, 0)))"}}>S E R V I C E S</motion.p></React.Fragment>} className={"framer-1dtoopl"} layoutDependency={layoutDependency} layoutId={"wUQOUGGjy"} style={{"--extracted-r6o4lv": "var(--token-5a6a7d77-3d7f-4cac-a6d6-7ea13b9dcea9, rgb(0, 0, 0))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={xh1Qt9538} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-hSj1m [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hSj1m .framer-jx5xkl { display: block; }", ".framer-hSj1m .framer-53ra4q { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1280px; }", ".framer-hSj1m .framer-vf9g6o { flex: none; height: 24px; position: relative; width: 24px; }", ".framer-hSj1m .framer-1dtoopl { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hSj1m .framer-53ra4q { gap: 0px; } .framer-hSj1m .framer-53ra4q > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-hSj1m .framer-53ra4q > :first-child { margin-left: 0px; } .framer-hSj1m .framer-53ra4q > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 1280
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"xh1Qt9538":"text"}
 * @framerImmutableVariables true
 */
const FramerpRJ1OjFXs: React.ComponentType<Props> = withCSS(Component, css, "framer-hSj1m") as typeof Component;
export default FramerpRJ1OjFXs;

FramerpRJ1OjFXs.displayName = "Section Title";

FramerpRJ1OjFXs.defaultProps = {height: 26, width: 1280};

addPropertyControls(FramerpRJ1OjFXs, {xh1Qt9538: {defaultValue: "S E R V I C E S", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramerpRJ1OjFXs, [])